import React from 'react'
import '../styles/Tagcloud.css'

function Tagcloud() {
  return (
<div className="blog_right_sidebar"> 
<aside className="single_sidebar_widget tag_cloud_widget">
<h4 className="widget_title">Tag Clouds</h4>
<ul className="list">
<li>
<a href="#">project</a>
</li>
<li>
<a href="#">love</a>
</li>
<li>
<a href="#">technology</a>
</li>
<li>
<a href="#">travel</a>
</li>
<li>
<a href="#">restaurant</a>
</li>
<li>
<a href="#">life style</a>
</li>
<li>
<a href="#">design</a>
</li>
<li>
<a href="#">illustration</a>
</li>
</ul>
</aside>
</div> 
  )
}

export default Tagcloud