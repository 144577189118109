import React, { useState, useEffect, useMemo } from 'react';
import EmptyList from '../../component/common/EmptyList';
import BlogList from '../../component/Home/BlogList';
import Header from '../../component/Home/Header/Index';
import SearchBar from '../../component/Home/SearchBar';
import axios from 'axios';

const LIMIT = 4;

const Home = () => {
  const [blogs, setBlogs] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]); // Separate state for filtered blogs
  const [activePage, setActivePage] = useState(1);

  // Pagination code
  const totalPagesCalculator = (total, limit) => {
    const pages = [];
    const pnum = Math.ceil(parseInt(total) / limit);
    for (let x = 1; x <= pnum; x++) {
      pages.push(x);
    }
    return pages;
  };

  // Load blogs on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://school-management-dashboard.onrender.com/blog/getblog');
        const blogData = response.data.blogs;
        setBlogs(blogData);
        setFilteredBlogs(blogData.slice(0, LIMIT)); // Initialize filteredBlogs with the first page
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Update filteredBlogs when activePage changes
  useEffect(() => {
    const startIndex = (activePage - 1) * LIMIT;
    const endIndex = startIndex + LIMIT;
    setFilteredBlogs(blogs.slice(startIndex, endIndex));
  }, [activePage, blogs]);

  // Handle search
  const handleSearch = () => {
    const filteredData = blogs.filter(blog =>
      blog.title.toLowerCase().includes(searchKey.toLowerCase().trim())
    );
    setFilteredBlogs(filteredData.slice(0, LIMIT)); // Display the first page of search results
  };

  // Clear search
  const clearSearch = () => {
    setSearchKey('');
    setActivePage(1); // Reset to the first page
    setFilteredBlogs(blogs.slice(0, LIMIT)); // Display the first page of all blogs
  };

  return (
    <div>
      {/* Page Header */}
      <Header />

      {/* Search Bar */}
      <SearchBar
        value={searchKey}
        clearSearch={clearSearch}
        formSubmit={handleSearch}
        handleSearchKey={e => setSearchKey(e.target.value)}
      />

      {/* Blog List & Empty View */}
      {!filteredBlogs.length ? <EmptyList /> : <BlogList blogs={filteredBlogs} />}

      {/* Pagination code */}
      <nav aria-label="Page navigation example">
        <ul className="pagination">
          {activePage !== 1 && (
            <li className="page-item" onClick={() => setActivePage(activePage - 1)}>
              <a className="page-link" href="javascript:void(null)">
                Previous
              </a>
            </li>
          )}
          {totalPagesCalculator(blogs.length, LIMIT).map(page => (
            <li className={`page-item ${activePage === page ? 'active' : ''}`} key={page}>
              <a className="page-link" href="javascript:void(null)" onClick={() => setActivePage(page)}>
                {page}
              </a>
            </li>
          ))}
          {activePage !== totalPagesCalculator(blogs.length, LIMIT).length && (
            <li className="page-item" onClick={() => setActivePage(activePage + 1)}>
              <a className="page-link" href="javascript:void(null)">
                Next
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Home;
