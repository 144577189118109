import React, { useState, useEffect } from "react";
import "../styles/Contact.css";
import axios from "axios";
import { AiOutlineHome } from "react-icons/ai";
import { IoMdPhonePortrait } from "react-icons/io";
import { HiOutlineMailOpen } from "react-icons/hi";
import { contactdataurl } from "../API/Api.js";

function Contact() {
  const [Section1Data, setSection1Data] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(contactdataurl);
        // Assuming the API response is an object with a 'data' property containing an array of objects
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const firstDataObject = response.data.data[0];
          setSection1Data(firstDataObject);
          console.log(firstDataObject);
          console.log("Helli i am amit");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Get form data and create a FormData object
    const form = e.target;
    const formData = new FormData(form);

    try {
      // Send a POST request to your API endpoint
      const response = await axios.post(
        "https://school-management-dashboard.onrender.com/contact/usercontactdata",
        formData
      );
      console.log(formData);

      // Handle the response here (e.g., show a success message)
      console.log("API response:", response.data);
      // Reset the form if needed
      form.reset();
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("API error:", error);
    }
  };
  return (
    <>
      <section className="first_sec">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-11 col-md-12">
              <div className="class_head">
                <h1 className="">{Section1Data.heading}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_form">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="contact-title">Get in Touch</h2>
            </div>
            <div className="col-lg-8">
              <form
                onSubmit={handleSubmit} // Attach the submit handler
                className="form-contact contact_form"
                method="post"
                id="contactForm"
                noValidate="novalidate"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="message"
                        id="message"
                        cols="30"
                        rows="9"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Message'"
                        placeholder="Enter Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="name" // Ensure that "name" matches the server-side expectation
                        id="name"
                        type="text"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter your name'"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control valid"
                        name="email"
                        id="email"
                        type="email"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter email address'"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="subject"
                        id="subject"
                        type="text"
                        onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Enter Subject'"
                        placeholder="Enter Subject"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <button
                    type="submit"
                    className="button button-contactForm boxed-btn"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <div className="media contact-info d-flex inter_div">
                <span className="contact-info__icon">
                  <AiOutlineHome />
                  <i className="ti-home"></i>
                </span>
                <div className="media-body">
                  <h3>{Section1Data.address}</h3>
                  {/* <p>Rosemead, CA 91770</p> */}
                </div>
              </div>
              <div className="media contact-info d-flex inter_div">
                <span className="contact-info__icon">
                  <IoMdPhonePortrait />
                  <i className="ti-tablet"></i>
                </span>
                <div className="media-body">
                  <h3>{Section1Data.mobile}</h3>
                  <p>Mon to Fri 9am to 6pm</p>
                </div>
              </div>
              <div className="media contact-info d-flex inter_div">
                <span className="contact-info__icon">
                  <HiOutlineMailOpen />
                  <i className="ti-email"></i>
                </span>
                <div className="media-body">
                  <h3>{Section1Data.email}</h3>
                  <p>Send us your query anytime!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
