import axios from "axios";

// we need to pass the baseURL as an object
const API = {
  // baseURL: "http://localhost:5000",
  baseURL: "https://school-management-dashboard.onrender.com",
};
export const homedataurl = API.baseURL + `/home/getdata`;
export const classdataurl = API.baseURL + `/class/getdata`;
export const aboutdataurl = API.baseURL + `/about/getdata`;
export const contactdataurl = API.baseURL + `/contact/getdata`;
