import React from "react";
import { Link } from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";
import Bloginner from "../../../Bloginner";
import { FaUser } from "react-icons/fa";


function TruncateText({ text, limit }) {
  const words = text.split(' ');
  const truncatedText = words.slice(0, limit).join(' ');

  return <>{truncatedText}{words.length > limit ? '...' : ''}</>;
}


function formatDateToCustom(dateString) {
  // Create a mapping of month numerical representations to their abbreviated form
  const monthMap = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  // Parse the input date string
  const date = new Date(dateString);

  // Extract day, month, and year from the parsed date
  const day = date.getDate();
  const month = monthMap[date.getMonth()];
  
  // Format the date as "[day month]" and return it
  const formattedDate = `${day} ${month}`;

  return formattedDate;
}

// Example usage:
// const inputDate = "2023-08-11T13:58:19.654+00:00";
// const formattedDate = formatDateToCustom(inputDate);
// console.log(formattedDate); // Output: "[11 Aug]"


const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    _id,
  },
}) => {
  const wordLimit = 30;
  const formattedDate = formatDateToCustom(createdAt); // Assuming the date string is in the format 'yyyy-mm-dd'

  return (
    <>
      {/* {day}
    {dateNumber} */}
      <div className="blogItem-wrap">
        <img
          className="blogItem-cover"
          src={require("../../../../../src/assest/single_blog_1.png")}
          alt="cover"
        />

        <div className="content_box">
          <p className="blog_item_date">{formattedDate}</p>
          <Link to={`/blog/${_id}`}>
            <h3>{title}</h3>
          </Link>
          <p className="blogItem-desc">  <TruncateText text={description} limit={wordLimit} /></p>
          <div className="d-flex cat_icon">
            <FaUser />
            <Chip label={category} />
          </div>
        </div>
        <footer>
          <div className="blogItem-author">
            {/* <img src={authorAvatar} alt='avatar' /> */}
            <div>
              {/* <h6>{authorName}</h6> */}
              {/* <p className='blog_item_date'>{createdAt}</p> */}
            </div>
            <Bloginner />
          </div>
          {/* <Link className='blogItem-link' to={`/blog/${_id}`}>
          ➝
        </Link> */}
        </footer>
      </div>
    </>
  );
};

export default BlogItem;
