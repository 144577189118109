import React from 'react'
import Blog from '../Pages/Blog'
import '../styles/Bloginner.css'

function Bloginner() {
  return (
    <>
    <Blog/>
    </>
  )
}

export default Bloginner